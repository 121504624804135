import { ViolationType } from "../ComplianceTabUtil";
import { ApiUrl } from "./ApiUrl";
import { PolygonGeometry, LineStringGeometry, INewZone, ICurbZone, ParkingAngle, StreetSide } from "./IDeployment";

interface IApiCreateZone extends INewZone {
    deployment_uuid: string;
}

type IApiUpdateZone = {
    curb_zone_id:            string;
    name:                    string;    
    user_zone_id:            string;
    street_name:             string;
    cross_street_start_name: string;
    cross_street_end_name:   string;
    parking_angle:           ParkingAngle;
    street_side:             StreetSide;
    num_spaces:              number;
    median:                  boolean;
};

// Generated by https://quicktype.io

export interface IAPIUpdateZoneResp {
    curb_zone:          ICurbZone;
    updated_properties: string[];
}

export interface Geometry {
    coordinates: Array<Array<number[]>>;
    type:        string;
}

export interface GISLine {
    coordinates: Array<number[]>;
    type:        string;
}


export class Api {
    private static postArgs( payload: any ) {
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify( payload ) };
    }

    private static putArgs( payload: any ) {
        return {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify( payload ) };
    }

    static getDeployment( deplId: string ) {
        return fetch( ApiUrl.deploymentDetails( deplId ) );
    }
    static getRealtimeData( deplId: string ) {
        const payload = {
            api_key:         "realtime-0109820938029832",
            deployment_uuid: deplId,
        }
        return fetch( ApiUrl.realtimeData(), { 
            method:  "POST",
            headers: { "Content-Type": "application/json" },
            body:    JSON.stringify( payload )
        } );
    }

    static createZone( deplId: string, zone: INewZone ) {
        let payload: IApiCreateZone = { deployment_uuid: deplId, ...zone };
        return fetch( ApiUrl.curbZoneCreate(), this.postArgs( payload ) );
    }
    
    static updateCurbZoneGeometry( zoneId: string,
                                   line:   LineStringGeometry ) {
        let payload = {
            curb_zone_id:    zoneId,
            gis_line:        line
        };
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

    static prepareUpdateObj = ( zn: ICurbZone): IApiUpdateZone => {
        return {
            curb_zone_id:            zn.curb_zone_id,
            name:                    zn.name,
            user_zone_id:            zn.user_zone_id,
            street_name:             zn.street_name,
            cross_street_start_name: zn.cross_street_start_name,
            cross_street_end_name:   zn.cross_street_end_name,
            parking_angle:           zn.parking_angle,
            street_side:             zn.street_side,
            num_spaces:              zn.num_spaces,
            median:                  zn.median,
        };
    }

    static updateZone( zone: ICurbZone ) {
        let payload = this.prepareUpdateObj( zone );
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

     static archiveZone( zoneId: string, archived: boolean = true ) {
        let payload = {
            curb_zone_id: zoneId,
            is_archived:  archived
        };
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

}

