import { MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";
import { Prompt } from "./Controls/Prompt";
import { CurbZoneGroupType, IZoneGroup } from "./IZoneGroup";
import { Setter, ifThenElse as condRet } from "./Util/MeasureTypes";
import { Confirm } from "./Controls/Confirm";
import { DotsVertical } from "heroicons-react";
import { ExitBtn } from "./Controls/ExitBtn";
import { Alert } from "./Controls/Alert";
import { RealtimeEntry, realtimeOccFraction } from "./SupplyTabUtil";
import { SupplyTabListCtxMenu } from "./SupplyTabListCtxMenu";
import { ICurbZone, IZone } from "./Api/IDeployment";
import { ZoneDialogEdit } from "./Controls/ZoneDialogEdit";
import { ZoneDialogAdv } from "./Controls/ZoneDialogAdvanced";
import { Api, IAPIUpdateZoneResp } from "./Api/Api";

export interface SupplyTabListProps {
    zones:           IZone[];
    setZones:        Setter<IZone[]>;
    realtimeData:    RealtimeEntry[];    
    customGroups:    IZoneGroup[];
    hoverZone:       IZone | undefined;
    setHoverZone:    Setter<IZone | undefined>;        
    zoneToggle:      ( zn:    IZone      ) => void;
    zoneOnClick:     ( zn:    IZone      ) => void;
    addZoneGroup:    ( znGrp: IZoneGroup ) => void;
    removeZoneGroup: ( znGrp: IZoneGroup ) => void;
    zoneDelete:      ( znId:  string     ) => void;
}

export function SupplyTabList( props: SupplyTabListProps ) {
    const [showNamePrompt, setShowNamePrompt] = useState<boolean>( false );
    const [showDelPrompt,  setShowDelPrompt ] = useState<boolean>( false );
    const [delCurbZoneGrp, setDelCurbZoneGrp] = useState<IZoneGroup>();
    const [showErr,        setShowErr       ] = useState<boolean>( false );
    
    const [showEdit,       setShowEdit      ] = useState<boolean>( false );
    const [editZone,       setEditZone      ] = useState<ICurbZone>();

    const [showAdv,        setShowAdv       ] = useState<boolean>( false );
    const [advZone,        setAdvZone       ] = useState<ICurbZone>();

    const { zones,
            realtimeData,
            customGroups,
            hoverZone,
            setZones,
            zoneToggle,
            zoneOnClick,
            addZoneGroup,
            removeZoneGroup
    } = { ...props };

    function selectAllCurbZones() {
        const set = !areAllZonesSelected();
        setZones( zones.map( zn => {
            return { ...zn, enabled: set };
        } ) );
    }

    function areAllSelected( custGrp: IZoneGroup ) {
        const enabledZones = getEnabledZones( custGrp );
        return enabledZones.every(
            customGrpZone => zones.findIndex(
                curbZone => curbZone.curb_zone_id === customGrpZone && curbZone.enabled ) !== -1 );
    }

    function getEnabledZones( custGrp: IZoneGroup ) {
        return custGrp.curbZones;
    }

    function handleGrpChange( custGrp: IZoneGroup ) {
        const set      = !areAllSelected( custGrp );
        const grpZones = getEnabledZones( custGrp );
        setZones( zones.map( cz => {
            const fnd = grpZones.findIndex( grpCurbZone => grpCurbZone === cz.curb_zone_id ) !== -1;
            if( !fnd ) {
                return cz;
            }
            return { ...cz, enabled: set };
        } ) );
    }

    function areAllZonesSelected() {
        return zones.every( zn => zn.enabled );
    }

    function showCreateCustomGroupDialog() {
        const enabled = zones.filter( zn => zn.enabled );
        if( enabled.length === 0 ) {
            setShowErr( true );
            return;
        }
        setShowNamePrompt( true );
    }
    
    function createNewGroup( newGroupName: string ) {
        const enabled = zones.filter( zn => zn.enabled );
        const ids     = enabled.map( zn => zn.curb_zone_id );
        addZoneGroup( { type:      CurbZoneGroupType.Custom,
                            name:      newGroupName,
                            curbZones: ids } );
    }

    
    function updateZone( zone: ICurbZone ) {
        setZones(
            zones.map(
                z => condRet( z.curb_zone_id === zone.curb_zone_id, { ...z, ...zone }, z )
            )
        );
    }

    //filter out archived zones here
    const visibleZones = zones.filter( zn => zn.is_archived !== true );
    return <div className="tab-supply-list">
        <div className="tab-supply-list-header">
            Zones
        </div>
        <div className="tab-supply-list-entries">
            <div className="ps-2 tab-supply-list-entries-row">
                <div className="tab-supply-list-entries-row-left">                
                    <img src="/img/vade-circle-v.svg" width={30} className="me-2" />
                    <div className="fw-bold me-auto">Select All Zones</div>
                </div>
                <div className="tab-supply-list-entries-row-switch">
                    <MDBSwitch checked={ areAllZonesSelected() }
                               onChange={ e => selectAllCurbZones() } />
                    <DotsVertical style={{ visibility: "hidden" }} />
                </div>
            </div>
            { visibleZones.map( zn => {
                const hovered   = zn.curb_zone_id === hoverZone?.curb_zone_id;
                const highlight = hovered ? "tab-supply-list-entries-row-highlight" : "";                
                return <div key={zn.curb_zone_id}
                            className={`ps-4 tab-supply-list-entries-row ${highlight}`}
                            onMouseEnter={ () => { props.setHoverZone( zn ) } }
                            onMouseLeave={ () => { props.setHoverZone( undefined ) } }>
                    <div className="tab-supply-list-entries-row-left"
                         onClick={ e => props.zoneOnClick( zn ) }>
                        <img src="/img/map-pin-modern-white.svg"
                            className="me-1"
                            style={{ maxHeight: "25px"}} />
                        <div className="text-capitalize">
                            {zn.name}
                        </div>
                        <div className="me-auto">
                            <div className="badge badge-primary p-1 ms-1 position-relative"
                                style={{ top: "0.025em" }}>
                                {realtimeOccFraction( zn, realtimeData )}
                            </div>
                            <> </> { /* very important that you do not remove this 
                                        though it may be educational for you to 
                                        locally in a dev environment */ }
                        </div>
                    </div>
                    <div className="tab-supply-list-entries-row-switch">
                        <MDBSwitch checked={zn.enabled}
                                   onChange={ e => { zoneToggle( zn ); } } />
                        <SupplyTabListCtxMenu
                            editDetails={ () => {
                                setEditZone( zn );
                                setShowEdit( true );
                            } }
                            editPolicy={ () => {
                                /* nothing to do yet */                                
                            } }
                            showDelete={() => {
                                setAdvZone( zn );
                                setShowAdv( true );
                            } } 
                            editDetailsEnabled={zn.is_user_defined}
                            editPolicyEnabled={false}
                            deleteEnabled={zn.is_user_defined} />
                    </div>
                </div>
            } ) }
        </div>
        <div className="tab-supply-list-header">
            Custom Curb Zone Groups <a href="#" onClick={ e => showCreateCustomGroupDialog() }>Add</a>
        </div>
        <div className="tab-supply-list-entries">
            { customGroups.map( curbZoneGrp =>
                <div key={curbZoneGrp.name}
                     className="ps-4 tab-supply-list-entries-row">
                    <div className="tab-supply-list-entries-row-left">
                        <img className="me-1" src="/img/map-pin-modern-yellow.svg" style={{ maxHeight: "24px" }} />
                        <div className="me-auto">{curbZoneGrp.name}</div>
                    </div>                    
                    <div className="tab-supply-list-entries-row-switch">
                        <MDBSwitch checked={areAllSelected( curbZoneGrp )}
                                   onChange={ e => { handleGrpChange( curbZoneGrp ); } } />
                        <ExitBtn onClick={() => {
                            setDelCurbZoneGrp( curbZoneGrp );
                            setShowDelPrompt( true );
                        }} />
                    </div>
                </div> ) }
        </div>
        <Confirm
            title="Delete Zone Group"
            label={`Are you sure you want to delete "${delCurbZoneGrp?.name}"?`}
            commitLabel="Delete"
            show={ showDelPrompt }
            setShow={ setShowDelPrompt }
            commit={ () => removeZoneGroup( delCurbZoneGrp! ) } />
        <Prompt
            title="Create Custom Zone Group"
            label="Custom Zone Group Name"
            show={showNamePrompt}
            setShow={setShowNamePrompt} 
            commit={createNewGroup} />
        <Alert commit={ () => {} } 
            title={"Invalid Selection"} 
            label={"Creating an Empty Zone Group is not allowed."} 
            confirmLabel={"OK"} 
            show={showErr} 
            setShow={setShowErr} /> 
        <ZoneDialogEdit
            show={showEdit} 
            setShow={setShowEdit} 
            zn={editZone}
            commit={ zn => {
                Api.updateZone( zn )
                    .then( r => r.json() )
                    .then( (json: IAPIUpdateZoneResp ) => {
                        updateZone( json.curb_zone );
                    } ) }
            } />
        <ZoneDialogAdv 
            show={showAdv}
            setShow={setShowAdv}
            cz={advZone}
            deleteZone={ zn => props.zoneDelete( zn.curb_zone_id ) } />
    </div>;
}