import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import { DateRangePicker } from "react-date-range";
import { vioDisp, ViolationType } from "./ComplianceTabUtil";
import { TimeRangeSliderUtil } from "./Controls/TimeRangeSliderUtil";
import { TimeRangeSlider } from "./Controls/TimeRangeSlider";
import { DaysOfWeekCtrl } from "./Controls/DaysOfWeekCtrl";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { DateUtil } from "./Util/DateUtil";
import { Dropdown } from "./Controls/Dropdown";

export interface ComplianceTabFilterBarProps {
    violationType:      ViolationType;
    setViolationType:   React.Dispatch<React.SetStateAction<ViolationType>>;

    startDate:          Date;
    setStartDate:       React.Dispatch<React.SetStateAction<Date>>;

    endDate:            Date;
    setEndDate:         React.Dispatch<React.SetStateAction<Date>>;

    daysOfWeek:         DayOfWeek[];
    setDaysOfWeek:      React.Dispatch<React.SetStateAction<DayOfWeek[]>>;

    timeRange:          Array<number>;
    setTimeRange:       React.Dispatch<React.SetStateAction<Array<number>>>;

    exportViolations:     () => string;
}

export function ComplianceTabFilterBar( props: ComplianceTabFilterBarProps ) {
    function rangeLabel() {
        return DateUtil.toSmallDateStr( props.startDate ) + " - " + DateUtil.toSmallDateStr( props.endDate );
    }

    function dayRangeLabel() {
        let letters = props.daysOfWeek.map( d => d.substring( 0, 2 ) );
        if( letters.length === 0 ) { return "No Days Selected"; }
        return letters.join( ", " ).toLowerCase();
    }

    function timeRangeLabel() {
        if( props.timeRange.length == 2 ) {
            return TimeRangeSliderUtil.dispHour( props.timeRange[0] ) + 
                " - " + TimeRangeSliderUtil.dispHour( props.timeRange[1] ); 
        }
        return "Error!";
    }

    const violationTypes = [ViolationType.All,
                            ViolationType.Bike,
                            ViolationType.DoubleParking,
                            ViolationType.NoParking,
                            ViolationType.NoStanding,
                            ViolationType.FireHydrant ];
    return <div className="tab-compliance-filter-bar">
        <div className="tab-compliance-filter-bar-left">
            <div className="form-floating">
                <MDBDropdown>
                    <MDBDropdownToggle>{vioDisp( props.violationType )}</MDBDropdownToggle>
                    <MDBDropdownMenu>
                        {violationTypes.map( vio =>
                            <MDBDropdownItem link key={vio} onClick={e => props.setViolationType( vio )}>
                                {vioDisp( vio )}
                            </MDBDropdownItem> )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={rangeLabel()} >
                    <DateRangePicker ranges={[{ startDate: props.startDate, endDate: props.endDate }]} 
                                        onChange={ r => {
                        const dr = r["range1"];
                        if( dr.startDate ) {
                            props.setStartDate( dr.startDate );
                        }
                        if( dr.endDate ) {
                            props.setEndDate( dr.endDate );
                        }
                    }} />
                </Dropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={dayRangeLabel()} 
                            style={{ minWidth: "16em" }}>
                                <div className="p-3 rounded-2 w-100" style={{ 
                                                            backgroundColor: "white",
                                                            filter:          "drop-shadow(15px 15px 15px #aaa7)", 
                                                            fontSize:        "0.875em" }}>
                        <div className="text-center">
                            <b>Days of Week</b>
                            <DaysOfWeekCtrl enabledDays={props.daysOfWeek} setEnabledDays={props.setDaysOfWeek} />    
                        </div>
                    </div>
                </Dropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={timeRangeLabel()} style={{ minWidth: "12em" }}>
                    <div className="p-3 rounded-2 w-100" style={{ 
                                                            backgroundColor: "white",
                                                            filter:          "drop-shadow(15px 15px 15px #aaa7)", 
                                                            fontSize:        "0.875em" }}>
                        <div className="text-center">
                            <b>Time Range</b>
                        </div>
                        <TimeRangeSlider
                            timeRange={props.timeRange}
                            setTimeRange={props.setTimeRange} />
                    </div>
                </Dropdown>
            </div>
        </div>
        <div className="mt-2 me-2"
             style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <MDBTooltip tag="span"
                        wrapperClass="float-end btn btn-link p-1"
                        title="Download Violations">
                <MDBIcon fas icon="file-download"
                    size="2x"
                    style={{ cursor: "pointer" }}
                    color="body"
                    onClick={ () => {
                        props.exportViolations();
                    } } />
            </MDBTooltip>
        </div>
    </div>;
}