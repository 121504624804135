import { ICam  } from "./ICam";
import { ILane } from "./ILane";
import { LatLng } from "./LatLng";

export enum ParkingAngle {
    Parallel      = "parallel",
    Perpendicular = "perpendicular",
    Angled        = "angled"
}

export enum StreetSide {
     N  = "N",  //"North"
     NE = "NE", //"Northeast"
     E  = "E",  //"East"
     SE = "SE", //"Southeast"
     S  = "S",  //"South"
     SW = "SW", //"Southwest"
     W  = "W",  //"West"
     NW = "NW"  //"Northwest"
}

export const StreetSides = [
    StreetSide.N ,
    StreetSide.NE,
    StreetSide.E ,
    StreetSide.SE,
    StreetSide.S ,
    StreetSide.SW,
    StreetSide.W ,
    StreetSide.NW,
];

export function dispStreetSide( side: StreetSide ) {
    switch( side ) {
        case StreetSide.N:  return "North"    ;
        case StreetSide.NE: return "Northeast";
        case StreetSide.E:  return "East"     ;
        case StreetSide.SE: return "Southeast";
        case StreetSide.S:  return "South"    ;
        case StreetSide.SW: return "Southwest";
        case StreetSide.W:  return "West"     ;
        case StreetSide.NW: return "Northwest";
        default:            return "Err!";
    }
}

export interface INewZone {
    street_name:     string;
    name:            string;
    user_zone_id:    string;
    geometry:        PolygonGeometry;    
    gis_line:        LineStringGeometry;
    is_user_defined: boolean;
}

export enum ZoneType {
    CurbParking = "curb_parking",
    NoParking   = "no_parking",
    ParkingLot  = "parking_lot",
    Violation   = "violation",
    Commercial  = "commercial_parking",
    BikeLane    = "bike",
}

export interface ICurbZone {
    curb_zone_type:          ZoneType;
    curb_zone_id:            string;
    geometry:                PolygonGeometry;
    curb_policy_ids:         any[];
    prev_policies:           any[];
    published_date:          string;
    last_updated_date:       string;
    prev_curb_zone_ids:      any[];
    start_date:              string;
    end_date:                null;
    location_references:     any[];
    name:                    string;
    user_zone_id:            string;
    street_name:             string;
    cross_street_start_name: string; 
    cross_street_end_name:   string;
    length:                  number;
    available_space_lengths: any[];
    availability_time:       null;
    width:                   number;
    parking_angle:           ParkingAngle;
    num_spaces:              number;
    street_side:             StreetSide;
    median:                  boolean;
    entire_roadway:          boolean;
    curb_area_ids:           any[];
    curb_space_ids:          any[];
    gis_line:                LineStringGeometry;
    is_user_defined:         boolean;
    is_archived:             boolean;
}

export interface PolygonGeometry {
    type:        "Polygon";
    coordinates: Array<Array<number[]>>;
}

export interface LineStringGeometry {
    type:        "LineString";
    coordinates: Array<number[]>;
}

export interface PointGeometry {
    type:        "Point";
    coordinates: Array<number>;
}

export interface DeplExtras {
    deploymentId:           string;
    defaultFilterStartDate: string;
    defaultFilterEndDate:   string;
    loc:                    LatLng;
}

export interface Info {
    tz:       string;   //iso time zone string such as "America/New_York"
    geometry: PointGeometry;
}

export interface IDeployment {
    uuid:         string;
    name:         string;
    info:         Info;
    boundary:     any;
    cameras:      Array<ICam>;
    lanes:        Array<ILane>;
    curb_zones:   Array<ICurbZone>;
    time_created: Date;
}

export interface IDepl {
    uuid:        string;
    name:        string;
    info:        Info;
    boundary:    any;
    cameras:     Array<ICam>;
    lanes:       Array<ILane>;
    curbZones:   Array<ICurbZone>;
    extras:      DeplExtras;
    timeCreated: Date;
}

export interface IZone extends ICurbZone { enabled: boolean; }

export function processZone( cz: ICurbZone ) {
    return { ...cz, enabled: true };
}

export function processZones( czs: ICurbZone[] ): IZone[] {
    return czs.map( processZone );
}

export function processDeplResp( deplResp: IDeployment,
                                 extras:   DeplExtras   ): IDepl {
    return { uuid:        deplResp.uuid,
             name:        deplResp.name,
             info:        deplResp.info,
             timeCreated: deplResp.time_created,
             boundary:    deplResp.boundary,
             cameras:     deplResp.cameras,
             lanes:       deplResp.lanes,
             curbZones:   deplResp.curb_zones,
             extras:      extras };
}
