import { useEffect, useState } from "react";
import { IUser } from "./Api/IUser";
import { getPathCenter } from "./Util/MapUtil";
import { IZoneGroup } from "./IZoneGroup";
import { IZone, IDepl, ICurbZone, processZone, LineStringGeometry } from "./Api/IDeployment";
import { LatLng } from "./Api/LatLng";
import { Api } from "./Api/Api";
import { RealtimeResp } from "./Api/Realtime";
import { RealtimeEntry } from "./Api/RealtimeEntry";
import { colorize } from "./SupplyTabUtil";
import { SupplyTabMap } from "./SupplyTabMap";
import { SupplyTabList } from "./SupplyTabList";
import { Setter } from "./Util/MeasureTypes";

export interface SupplyTabProps {
    user:            IUser;
    depl:            IDepl;
    setDepl:         Setter<IDepl | undefined>;
    zones:           IZone[];
    setZones:        Setter<IZone[]>;
    customGroups:    IZoneGroup[];
    addZoneGroup:    ( curbZoneGroup: IZoneGroup ) => void;
    removeZoneGroup: ( curbZoneGroup: IZoneGroup ) => void;
}

export function SupplyTab( props: SupplyTabProps ) {
    const [gMap,          setGMap         ] = useState<google.maps.Map>();
    const [zoom,          setZoom         ] = useState<number>( 16 );
    const [loc,           setLoc          ] = useState<LatLng>( props.depl.extras.loc );
    const [hoverZone,     setHoverZone    ] = useState<IZone>();
    const [realtimeData,  setRealtimeData ] = useState<RealtimeEntry[]>( [] );
    const [zones,         setZones        ] = [props.zones, props.setZones];

    async function getRealtimeData( deplId: string ) {
        const res = await Api.getRealtimeData( deplId );
        const json: RealtimeResp = await res.json();
        const rtData = json.realtime_data.map( el => { return { ...el, value: 0, color: "", units: "" } } );
        setRealtimeData( colorize( rtData ) );
    }

    const depl = props.depl;
    useEffect( () => {
        getRealtimeData( depl.uuid );
    }, [depl] );

    if( !realtimeData ) { return <></>; }

    function handleChange( zn: IZone ) {
        const updatedCurbZones = zones.map( curbZone => {
            if( curbZone.curb_zone_id !== zn.curb_zone_id ) {
                return curbZone;
            }
            return { ...curbZone, enabled: !curbZone.enabled };
        } );
        setZones( updatedCurbZones );
    }

    function updateCurbZoneLine( zoneId: string, gisLine: LineStringGeometry ) {
        const updated: IZone[] =
            zones.map<IZone>(
                zn => zn.curb_zone_id !== zoneId ? zn : { ...zn, gis_line: gisLine } );
        setZones( updated );
    }

    function deleteZone( zoneId: string ) {
        const updated = zones.map( (zn): IZone =>
            zn.curb_zone_id !== zoneId
                ? zn : { ...zn, is_archived: true } );
        setZones( updated );
    }

    function changeZoom() {
        const zm = gMap?.getZoom();
        if( !zm ) { return; }
        setZoom( zm );
    }

    function panToZone( zn: IZone ): void {
        let path: google.maps.LatLngLiteral[] = zn.gis_line.coordinates.map( p => {
            return { lat: p[1], lng: p[0] }
        } );
        setLoc( getPathCenter( path ) );
    }

    const containerStyle = { width: "100%", height: "100%" };    
    const visibleZones = zones.filter( zn => zn.is_archived !== true );
    return <div className="tab-supply bg-offwhite">
        <SupplyTabList
            zones={visibleZones}
            setZones={setZones}
            hoverZone={hoverZone}
            setHoverZone={setHoverZone}
            zoneOnClick={panToZone}
            zoneToggle={handleChange}
            realtimeData={realtimeData}
            customGroups={props.customGroups}
            addZoneGroup={props.addZoneGroup}
            removeZoneGroup={props.removeZoneGroup}
            zoneDelete={ znId => {
                Api.archiveZone( znId, true ).then( r => r.json() ).then( json => {
                    deleteZone( znId );
                } );
            } }
        />
        <SupplyTabMap
            containerStyle={containerStyle}
            depl={depl}
            zoom={zoom}
            setZoom={setZoom}            
            loc={loc}
            setLoc={setLoc}
            cams={depl.cameras}
            zones={visibleZones}
            realtimeData={realtimeData}
            zoneHover={    zn => setHoverZone( zn ) }
            zoneDblClick={ zn => handleChange( zn ) }
            zoneCreate={ zn => {
                Api.createZone(
                    props.depl.uuid, zn
                ).then( resp => resp.json() )
                 .then( (json: { curb_zone: ICurbZone } ) => {
                     props.setZones( [ ...props.zones, processZone( json.curb_zone ) ] );
                } );
            } }
            hoverZone={hoverZone}
            setHoverZone={setHoverZone}
            onLoad={ m => setGMap( m ) }
            onZoomChanged={ () => changeZoom() }
            updateCurbZoneLine={ (id, line) => updateCurbZoneLine( id, line ) }>                    
        </SupplyTabMap>
    </div>;
}
