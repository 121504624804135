import { MDBSwitch } from "mdb-react-ui-kit";
import React from "react";
import { DayOfWeek as DayOfWeek, daysOfWeek } from "./DayOfWeek";

interface IDayEnabled {
    day:     DayOfWeek;
    enabled: boolean;
}

interface DaysOfWeekCtrlProps {
    enabledDays:    DayOfWeek[];
    setEnabledDays: ( daysOfWeek: DayOfWeek[] ) => void;
}

export function DaysOfWeekCtrl( props: DaysOfWeekCtrlProps ) {
    function handleDayChange( day: DayOfWeek ) {
        const enabledDays = [...props.enabledDays];
        const ind         = enabledDays.indexOf( day );
        if( ind === -1 ) {
            enabledDays.push( day ); //add / enable
        }
        else {
            enabledDays.splice( ind, 1 ); //remove / disable
        }
        const sorted        = [...daysOfWeek];
        const enabledSorted = sorted.filter( s => enabledDays.indexOf( s ) !== -1 );
        props.setEnabledDays( enabledSorted );
    }

    const daysToRender: IDayEnabled[] = daysOfWeek.map( el => {
        return { day: el, enabled: props.enabledDays.indexOf( el ) !== -1 };
    } );
    return <div style={{
            display:             "grid",
            gridTemplateColumns: "auto auto",
            gridTemplateRows:    "repeat( auto-fill, auto )" }}>
            {daysToRender.map( d => {
                return <React.Fragment key={d.day}>
                    <div className="text-start">{d.day}</div>
                    <div><MDBSwitch checked={d.enabled} onChange={ e => handleDayChange( d.day ) } /></div>
                </React.Fragment>
            } )}
    </div>;
}
