
export type Setter<T> = React.Dispatch<React.SetStateAction<T>>;
export type Action<T> = ( arg: T ) => void;

//one of the coolest line of code I've written in a while
//takes any type and constructs a new type that may contain
//one or more of the properties of the original type
export type Options<T> = { [Property in keyof T]+?: T[Property]; };

//short hand funcs with nice properties
export const ifThenElse = <T>( cond: boolean, v1: T, v2: T ) => cond ? v1 : v2;
export const at         = <T>( arr: [T, T], ind: boolean )   => arr[+ind];

//not sure I want to use these yet...
export type FromApiType<T> = T extends object
  ? { [K in keyof T as FromApiField<K & string>]: FromApiType<T[K]>; } : T;

type FromApiField<S extends string> =
  S extends `${infer First}_${infer Rest}` ? `${Lowercase<First>}${FromApiField<Capitalize<Rest>>}` : S;