import { DayOfWeek, daysOfWeek } from "./Controls/DayOfWeek";

export enum TimeGrouping {
    ByDay  = "By Day",
    ByHour = "By Hour"
}

export function getInitialDaysOfWeek(): DayOfWeek[] {
    const days = [...daysOfWeek];
    return days;
}

const errMsg = "Couldn't find card for activity";
export function getChartBaseUrl( activity: string, timeGrp: TimeGrouping ) {
    if( timeGrp === TimeGrouping.ByDay  ) { return getByDayChartBaseUrl ( activity ); }
    if( timeGrp === TimeGrouping.ByHour ) { return getByHourChartBaseUrl( activity ); }
    console.error( errMsg );
    return "Err!";
}

export function getTableBaseUrl( activity: string, timeGrp: TimeGrouping ) {
    if( timeGrp === TimeGrouping.ByDay  ) { return getByDayTableBaseUrl ( activity ); }
    if( timeGrp === TimeGrouping.ByHour ) { return getByHourTableBaseUrl( activity ); }
    console.error( errMsg );
    return "Err!";
}

//daily chart
function getByDayChartBaseUrl( activity: string ) {
    if( activity == "Unique Sessions"  ) { return "https://public.domo.com/cards/Y6yjK"; }
    if( activity == "Dwell Time"       ) { return "https://public.domo.com/cards/1jGgR"; }
    if( activity == "Peak Occupancy"   ) { return "https://public.domo.com/cards/X6rgW"; }
    if( activity == "Avg. Utilization" ) { return "https://public.domo.com/cards/2R7jK"; }
    console.error( errMsg );
    return "Err!";
}

//hourly chart
function getByHourChartBaseUrl( activity: string ) {
    if( activity == "Unique Sessions"  ) { return "https://public.domo.com/cards/El0Jv"; }
    if( activity == "Dwell Time"       ) { return "https://public.domo.com/cards/GZML7"; }
    if( activity == "Peak Occupancy"   ) { return "https://public.domo.com/cards/JZNO2"; }
    if( activity == "Avg. Utilization" ) { return "https://public.domo.com/cards/KOLPR"; }
    console.error( errMsg );
    return "Err!";
}

//daily table
function getByDayTableBaseUrl( activity: string ) {
    if( activity == "Unique Sessions"  ) { return "https://public.domo.com/cards/58RmB"; }
    if( activity == "Dwell Time"       ) { return "https://public.domo.com/cards/66Bnn"; }
    if( activity == "Peak Occupancy"   ) { return "https://public.domo.com/cards/76poj"; }
    if( activity == "Avg. Utilization" ) { return "https://public.domo.com/cards/8qMpm"; }
    console.error( errMsg );
    return "Err!";
}

//hourly table
function getByHourTableBaseUrl( activity: string ) {
    if( activity == "Unique Sessions"  ) { return "https://public.domo.com/cards/98Dqx"; }
    if( activity == "Dwell Time"       ) { return "https://public.domo.com/cards/0g05v"; }
    if( activity == "Peak Occupancy"   ) { return "https://public.domo.com/cards/g5nrY"; }
    if( activity == "Avg. Utilization" ) { return "https://public.domo.com/cards/j20xR"; }
    console.error( errMsg );
    return "Err!";
}
    

