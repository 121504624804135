import { Menu, MenuItem } from "@mui/material";
import { DotsVertical } from "heroicons-react";
import { CSSProperties, ReactNode, useState } from "react";

export interface SupplyTabListCtxMenuProps {
    editDetailsEnabled: boolean;
    editDetails: () => void;
    
    editPolicyEnabled: boolean;
    editPolicy: () => void;

    deleteEnabled: boolean;
    showDelete: () => void;
}

export function SupplyTabListCtxMenu( props: SupplyTabListCtxMenuProps ) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const open                    = Boolean( anchorEl );
    
    const handleClick = ( event: any ) => { setAnchorEl( event.currentTarget ); };
    const handleClose = (            ) => { setAnchorEl( null );                };

    function RenderIf( props: { cond: boolean, children: ReactNode } ) {
        if( props.cond ) { return <>{props.children}</>; }
        return <></>;
    }

    const noOptions = !(props.editDetailsEnabled || props.editPolicyEnabled || props.deleteEnabled);
    const style: CSSProperties = noOptions ? { visibility: "hidden" } : {};
    return <>
        <DotsVertical
            style={{ ...style, cursor: "pointer", height: 24, width: 24 }}
            className="float-end"
            onClick={handleClick} />
        <Menu anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <div>                
                <RenderIf cond={props.editDetailsEnabled}>
                    <MenuItem onClick={ () => { props.editDetails(); handleClose(); }}>
                        Edit Details
                    </MenuItem>
                </RenderIf>
                <RenderIf cond={props.editPolicyEnabled}>
                    <MenuItem disabled onClick={ () => { props.editDetails(); handleClose(); }}>
                        Edit Policy (Coming Soon!)
                    </MenuItem>
                </RenderIf>
                <RenderIf cond={props.deleteEnabled}>
                    <MenuItem onClick={ () => { props.showDelete(); handleClose(); }}>
                        Delete Zone
                    </MenuItem>
                </RenderIf>
            </div>
        </Menu>
    </>;
}
