import { LoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { DeplExtras, IZone, IDepl, IDeployment, processDeplResp, processZones } from "./Api/IDeployment";

//style
import "./App.css";

//firebase-ui for auth and firestore
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { DocumentData, getDocs, Query, query, where } from "firebase/firestore"; 
import { Api } from "./Api/Api";
import { VadeFirebase } from "./Api/VadeFirebase";
import { IUser } from "./Api/IUser";
import { AppTabs } from "./AppTabs";
import { getAuth } from "firebase/auth";
import FirebaseAuth from "./AppLogin";

//requires 'any', if you chg to 'string' it *will* break
const libs: any[] = ["drawing", "places", "visualization"];

export function App() {
    const [loaded,       setLoaded      ] = useState<boolean>( false ); 
    const [fbUser,       setFbUser      ] = useState<firebase.User>();
    const [user,         setUser        ] = useState<IUser>();
    const [depl,         setDepl        ] = useState<IDepl>();
    const [zones,        setZones       ] = useState<IZone[]>( [] );
    const [vadeFirebase, setVadeFirebase] = useState<VadeFirebase>( new VadeFirebase() );

    //listen to the firebase auth state and set local state
    useEffect( () => {
        const unregAuthObserver = firebase.auth().onAuthStateChanged( user => {
            if( user ) {
                setFbUser( user );
                return;
            }
            setLoaded( true );
        } );
        return () => unregAuthObserver(); // make sure we un-register firebase observers when the component unmounts.
    }, [] );

    async function getUserData( query: Query<DocumentData> ) {
        const res = await getDocs( query );
        if( res.empty ) {
            console.error( "couldn't authenticate!" );
            setLoaded( true );
            return;
        }
        const doc = res.docs[0];
        if( !doc.data() ) {
            console.error( "couldn't authenticate!" );
            setLoaded( true );
            return;
        }
        const userData: IUser = doc.data() as IUser;
        Api.getDeployment( userData.deploymentId ).then( resp => resp.json() )
                                                  .then( json => {
            let depl: IDeployment = { ...json.deployment };
            depl.curb_zones = depl.curb_zones.filter( cz => !cz.name.includes( "violation" ) );
            depl.curb_zones = depl.curb_zones.filter( cz => !cz.name.includes( "shared"    ) );
            vadeFirebase.getDepl( userData.deploymentId ).then( resp => {
                if( userData ) {
                    let deplExtras = (resp.data() as DeplExtras);
                    deplExtras.loc = { lat: depl.info.geometry.coordinates[1], 
                                       lng: depl.info.geometry.coordinates[0] };
                    setDepl( processDeplResp( depl, deplExtras ) );
                    setZones( processZones( depl.curb_zones ) );
                    setUser( userData );
                }
                else {
                    alert( "Error!" );
                }
                setLoaded( true );
            } );
        } );
    }

    useEffect( () => {
        if( !fbUser ) { return; }
        const q = query( vadeFirebase.users, where( "email", "==", fbUser.email ) );
        getUserData( q );
    }, [fbUser] );

    function logout() {
        getAuth().signOut();
        setUser( undefined );
    }

    if( !loaded ) {
        return <></>;
    }

    if( !user ) {
        return <FirebaseAuth uiConfig={vadeFirebase.uiConfig} 
                             firebaseAuth={firebase.auth()} />
    }

    if( !depl ) {
        return <></>;
    }

    return <>
        <LoadScript googleMapsApiKey="AIzaSyAcdlV9hsHL9qllzRgttb0-1JGsaJt6-Rw" libraries={libs}>
            <AppTabs user={user}
                     depl={depl}
                     zones={zones}
                     setZones={setZones}
                     setDepl={setDepl}
                     logout={logout} />
        </LoadScript>
    </>;
}
