
export enum DayOfWeek {
    Monday    = "Monday",
    Tuesday   = "Tuesday",
    Wednesday = "Wednesday",
    Thursday  = "Thursday",
    Friday    = "Friday",
    Saturday  = "Saturday",
    Sunday    = "Sunday"
}

export const daysOfWeek: DayOfWeek[] = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,  
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,   
    DayOfWeek.Saturday, 
    DayOfWeek.Sunday
]